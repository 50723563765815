<template>
  <div id="petlist-view">
    <section class="bg-light">
      <main></main>
      <div class="col-md-12">
        <div class="findgft">
          <router-link
            to="/wishlist"
            class="sm_megamenu_head sm_megamenu_drop "
            id="sm_megamenu_286"
            href=""
          >
            <h1 class="display-4">Pet Wishlist</h1></router-link
          >
        </div>
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid  "
            alt
          />
        </div>
      </div>
      <div class="container-fluid pt-5"></div>
      <p v-if="imageshow">
        <span class="d-flex justify-content-center"
          ><img
            class="img-fluid text-center"
            src="@/assets/site/images/Group1.svg"
            alt=""
        /></span>
      </p>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <ul>
              <li
                v-for="pet in pets"
                class="sm_megamenu_title ml-4 my-3"
                @click="showOccasionList(pet)"
              >
                <h2>
                  <span class="col-md-3">
                    <img
                      :src="pet.image"
                      width="30"
                      height="40"
                      class="img-fluid"
                      alt=""
                    />
                  </span>
                  {{ pet.name }}
                </h2>
              </li>
            </ul>
          </div>
          <div class="col-md-9">
            <petwishlist-view
              v-if="show"
              v-bind:wishlistproducts="wishlistproducts"
            ></petwishlist-view>
          </div>
        </div>
      </div>

      <div class="container">
        <hr />
      </div>
    </section>

    <!-- End Footer -->
  </div>
</template>
<script>
import PetwishlistView from "@/views/site/user/PetwishlistView.vue";

export default {
  name: "PetslistView",
  metaInfo: {
    title: "Pet Wishlist",
    meta: [
      {
        name: "description",
        content:
          "Explore your pet wishlist with ease! Browse through various pet gift ideas and manage your curated lists effortlessly, ensuring your furry friends receive the best gifts for every occasion.",
      },
      {
        name: "keywords",
        content:
          "pet wishlist, gift ideas for pets, manage pet gifts, pet gift ideas, pet lovers, curated gift lists, occasion-based pet gifts, user-friendly navigation, gift management for pets, pet care, festive pet gifts, personalized pet suggestions, online pet shopping, pet owner essentials, animal gift ideas",
      },
    ],
  },
  data() {
    return {
      loader: true,
      errors: [],
      pages: [],
      pets: [],
      wishlistproducts: [],
      imageshow: false,
      wishlist_status: null,
      show: false,
    };
  },
  components: { PetwishlistView },
  created() {
    this.getWishlistProducts();
  },
  methods: {
    getWishlistProducts() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/userwishlist/getwishlistProducts";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          wishlist_type: "Pet",
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.wishlistproducts = data.wishlist;
          this.wishlist_status = data.wishlist_status;
          if (this.wishlistproducts.length == 0) {
            this.imageshow = true;
          }
          this.pets = data.PetList;
        });
    },
    showOccasionList(contact) {
      var page_name = this.$route.meta.title;
      var path = this.$route.path;
      this.$router.replace({
        name: "Occasionwishlist",
        params: {
          occasion_list: contact.occasion_lists,
          page_name: page_name,
          path: path,
        },
      });
    },
  },
};
</script>
